<template>
  <div
    class="navigation-section w-full rounded-md"
    :class="{'collapsed': navState === 'mini'}"
  >
    <button
      v-for="item in items"
      v-show="item.hide !== true"
      :key="item.icon"
      class="flex items-center nav-button rounded-md w-full relative"
      :class="{ 'active': item.isActive }"
      size="2xs"
      @click.prevent.stop="item.action()"
    >
      <SvgIcon
        :icon="item.icon"
        height="20"
        width="20"
        class="icon"
      />
      <div v-if="navState === 'full'" class="label">
        {{ item.label }}
      </div>
      <NavigationToolTip :label="item.label"/>
    </button>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useUiStore } from '@/store/ui';
import { useNavigation } from '@/composables/useNavigation';

const uiStore = useUiStore();
const { navState, } = storeToRefs(uiStore);

const { sportsBettingRedirect, } = useNavigation();

const route = useRoute();

const items = computed(() => [
  {
    icon: 'blog',
    label: 'Blog',
    isActive: route.path.includes('providers'),
    hide: true,
    action: () => sportsBettingRedirect('/blog'),
  },
  {
    icon: 'support',
    label: 'Support',
    isActive: route.path.includes('contact-us'),
    hide: false,
    action: () => sportsBettingRedirect('/contact-us'),
  },
]);
</script>
